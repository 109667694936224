


















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import Rewards from '@/datasource/rewards/common.reward.vue';
import VueRouter from 'vue-router';
import { farmStores } from '@/store/poultry/farm';
import { ToastProgrammatic as Toast } from 'buefy';
import { clientStores } from '@/store/poultry/client';

@Component({
  components: {
    Modal
  }
})
export default class DeleteFarm extends Vue {
  store = farmStores.details;
  showDeleteModal = false;

  async handleDeleteFarm() {
    try {
      await this.store.delete();
      if (this.store.isDeleted) {
        Toast.open({
          type: 'is-danger',
          message: 'Farm deleted',
        });
        await farmStores.list.fetchAll();
        this.closeDeleteModal();
      }
    } catch(error) {
      Toast.open({
        type: 'is-danger',
        message: 'Farm cannot be deleted',
      });
    }
  }

  @Emit('onClose')
  closeDeleteModal() {}
}
