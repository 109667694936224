












import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import DropdownFilter from '@/components/poultry/DropdownFilter.vue';
import { dropdownStores } from '@/store/poultry/dropdowns';
import { farmStores } from '@/store/poultry/farm';

@Component({
  components: {
    DropdownFilter
  }
})
export default class CityFilter extends Vue {
  farmStore = farmStores.list;
  countryStore = dropdownStores.country;
  cityOptions: any = [];
  @Ref() resetSelectedData!: HTMLFormElement;

  async created() {
    this.cityOptions = [...this.cities];
  }

  get currentRegion() {
    return this.farmStore.apiParams.state;
  }

  get cities(): any {
    return this.countryStore.cities.map(city => {
      return {
        text: city.name,
        value: city.id.toString()
      }
    });
  }

  @Watch('currentRegion')
  async onStateChanged() {
    this.resetSelectedData['selectedData'] = [];
    this.farmStore.fetchAll({
      cities: [],
      ...this.farmStore.apiParams,
      ...this.farmStore.pagination,
    });
  }

  searchKeyword(keyword: any) {
    if (keyword.trim() === '') {
      this.cityOptions = [...this.cities];
      return;
    }
    this.cityOptions = [...this.cities].filter(city => {
      return city.text.toLowerCase().includes(keyword.toLowerCase());
    });
  }

  handleCitiesSelected(cities: []) {
    this.farmStore.fetchAll({
      ...this.farmStore.apiParams,
      ...this.farmStore.pagination,
      cities,
    });
  }
}
