






































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import StateFilter from '@/views/poultry/Client/Farm/FarmTableFilter/StateFilter.vue';
import CityFilter from '@/views/poultry/Client/Farm/FarmTableFilter/CityFilter.vue';

@Component({
  components: {
    StateFilter,
    CityFilter
  }
})

export default class FarmPageHeader extends Vue {
  keywords: string = '';

  @Emit('onSearch')
  searchByKeywords() {
    return this.keywords;
  }

  @Emit('onCreateNewFarm')
  createNewFarm() {}
}
