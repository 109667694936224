
























import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import { farmStores } from '@/store/poultry/farm';
import { FarmLocationDetails, FarmResponse } from '@/models/poultry/farm.model';
import FarmDetails from './FarmDetails.vue';
import EditFarmForm from './EditFarmForm.vue';
import { getRowNumber } from '@/utils/pagination';

@Component({
  components: {
    Modal,
    FarmDetails,
    EditFarmForm
  }
})
export default class FarmTableBody extends Vue {
  listStore = farmStores.list;
  detailStore = farmStores.details;
  showFarmDetailsModal: boolean = false;
  showEditFarmModal: boolean = false;

  created() {
    this.listStore.fetchAll();
  }

  get farms(): FarmResponse[] {
    return this.listStore.sortedFarms;
  }

  getRowNumber() {
    return getRowNumber(this.listStore.pagination);
  }

  handleFarmDetails(farm: FarmResponse) {
    this.detailStore.updateFarm(farm);
    this.showFarmDetailsModal = !this.showFarmDetailsModal;
  }

  getLocation(params: FarmLocationDetails) {
    const farmsLocation = [];
    if (params.city) {
      farmsLocation.push(params.city.name);
    }
    if (params.state) {
      farmsLocation.push(params.state.name);
    }

    if (!farmsLocation.length) {
      return '-';
    } else {
      return farmsLocation.join(', ');
    }
  }

  handleEditFarm(id: number) {
    this.detailStore.setFarmId(id);
    this.showEditFarmModal = true;
  }
}
