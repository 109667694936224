












import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import FarmTable from './FarmTable.vue';
import { farmStores } from '@/store/poultry/farm';
import Modal from '@/components/poultry/Modal.vue';
import CreateFarmForm from './CreateFarmForm.vue';
import FarmPageHeader from './FarmPageHeader.vue';

@Component({
  components: {
    FarmTable,
    Modal,
    CreateFarmForm,
    FarmPageHeader
  },
})
export default class Farm extends Vue {
  store = farmStores.list;
  showCreateFarmModal = false;

  async searchByKeywords(inputKeywords: any) {
    if (!inputKeywords.trim()) {
      // Reset initial result
      await this.store.fetchAll(this.store.defaultPagination);
      return;
    }
    this.store.searchByKeywords({
      keywords: inputKeywords,
    });
  }
}
