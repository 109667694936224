














import { Component, Vue, Ref } from 'vue-property-decorator';
import DropdownFilter from '@/components/poultry/DropdownFilter.vue';
import { dropdownStores } from '@/store/poultry/dropdowns';
import DropdownFilterAlt from '@/components/poultry/DropdownFilterAlt.vue';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { farmStores } from '@/store/poultry/farm';

@Component({
  components: {
    DropdownFilterAlt,
    DropdownFilter,
  },
})
export default class StateFilter extends Vue {
  countryStore = dropdownStores.country;
  farmStore = farmStores.list;
  stateOptions: any = [];
  stateIds = [];
  defaultState = {
    text: '',
    value: '',
  };
  @Ref() activeSelectedDropdown!: any;

  async created() {
    await this.countryStore.fetchCountries();
    await this.countryStore.fetchStatesByCountryCode();
    this.stateOptions = [...this.states];
  }

  get states(): any {
    const states = this.countryStore.regions.map((data) => {
      return {
        text: data.name,
        value: data.id.toString(),
      };
    });

    return [
      {
        value: '',
        text: 'States',
      },
      ...states,
    ];
  }

  handleSelectedState(params: DropdownOption) {
    this.activeSelectedDropdown['isActive'] = true;

    if (params.value) {
      this.countryStore.fetchCityByStateCode(params.value);
      this.farmStore.fetchAll({
        ...this.farmStore.apiParams,
        state: params.value
      });
    }

    this.farmStore.fetchAll({
      state: params.value,
      cities: []
    });
  }

  searchKeyword(keyword: any) {
    if (keyword.trim() === '') {
      this.stateOptions = [...this.states];
      return;
    }
    this.stateOptions = [...this.states].filter((country) => {
      return country.text.toLowerCase().includes(keyword.toLowerCase());
    });
  }
}
