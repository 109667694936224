












import { Vue, Component } from 'vue-property-decorator';
import { farmStores } from '@/store/poultry/farm';
import { FarmLocationDetails } from '@/models/poultry/farm.model';

@Component({
  components: {
  }
})
export default class FarmDetails extends Vue {
  store = farmStores.details;
  farm: FarmLocationDetails | null = this.store.farms;

  get getLocation(): string {
    const farmLocation = [];
    if (this.farm!.addressLine1) {
      farmLocation.push(this.farm!.addressLine1);
    }
    if (this.farm!.addressLine2) {
      farmLocation.push(this.farm!.addressLine2);
    }
    if (this.farm!.postcode) {
      farmLocation.push(this.farm!.postcode);
    }
    if (this.farm!.city) {
      farmLocation.push(this.farm!.city.name);
    }
    if (this.farm!.state) {
      farmLocation.push(this.farm!.state.name);
    }

    if (!farmLocation.length) {
      return '-';
    } else {
      return farmLocation.join(', ');
    }
  }
}
