









import { Vue, Component } from 'vue-property-decorator';
import { farmStores } from '@/store/poultry/farm';
import FarmTableHeader from './FarmTableHeader.vue';
import FarmTableBody from './FarmTableBody.vue';
import Pagination from '@/components/poultry/Pagination.vue';
import { PaginationModel } from '@/models/poultry/pagination.model';

@Component({
  components: {
    FarmTableHeader,
    FarmTableBody,
    Pagination
  }
})
export default class FarmTable extends Vue {
  store = farmStores.list;

  handlePageCountChanged(params: PaginationModel) {
    this.store.fetchAll(params);
  }
}
