















































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import { dropdownStores } from '@/store/poultry/dropdowns';
import { farmStores } from '@/store/poultry/farm';
import { constructValidationError } from '@/utils/errors';
import { Country, Region, City } from '@/models/poultry/country.model';
import DeleteFarm from './DeleteFarm.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/store/poultry/endpoints';

@Component({
  components: {
    Modal,
    DeleteFarm
  }
})
export default class EditFarmForm extends Vue {
  store = farmStores.details;
  countryStore = dropdownStores.country;
  showDeleteModal = false;
  errors: { [key: string]: string } = {};

  countries: Country[] = [];
  states: Region[] = [];
  cities: City[] = [];

  @Watch('error')
  get validationErrors() {
    const error = constructValidationError(this.store.error);
    return error;
  }

  get farm() {
    return this.store.farms;
  }

  async created() {
    await this.store.fetchById();
    await this.fetchCountriesDropdown();
    await this.fetchRegionsDropdown();
    await this.fetchCitiesDropdown();
  }

  async fetchCountriesDropdown() {
    try {
      const { data } = await axios.get<Country[]>(Endpoints.Countries);
      this.countries = data;
    } catch (error) {
      console.error(error)
    }
  }

  async fetchRegionsDropdown() {
    try {
      const params: any = {};

      if (this.farm && this.farm.countryId ) {
        params['country'] = this.farm.countryId;
      }

      const { data } = await axios.get<Region[]>(Endpoints.States, {
        params,
      });

      this.states = data;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchCitiesDropdown() {
    try {
      const params = {} as { [key: string]: string };

      if (this.farm && this.farm.stateId) {
        params['state'] = this.farm.stateId;
      }

      const { data } = await axios.get(Endpoints.Cities, {
        params,
      });

      this.cities = data;
    } catch (error) {
      console.error(error);
    }
  }

  async handleFormSubmitted() {
    try {
      if (this.farm && this.farm.name && this.farm.countryId && this.farm.cityId && this.farm.stateId) {
        await this.store.update();

        if (this.store.isUpdated) {
          Toast.open({
            type: 'is-success',
            message: 'Farm successfully updated',
          });
          await farmStores.list.fetchAll();
          this.closeEditFarmModal();
        }
      } else {
        Toast.open({
          type: 'is-danger',
          message: 'Required fields cannot be empty',
        });
      }
    } catch (error) {
      Toast.open({
        type: 'is-danger',
        message: 'Farm cannot be updated',
      });
    }
  }

  handleLocationChanged(type: string) {
    if (type === 'city') {
      // Nothing to do here
      return;
    } else if (type === 'country' && this.farm) {
      if (!this.farm.countryId) {
        this.states = [];
        this.cities = [];
        this.farm.stateId = '';
        this.farm.cityId = '';
      } else {
        this.fetchRegionsDropdown();
      }
    } else if (type === 'state' && this.farm) {
      if (!this.farm.stateId) {
        this.farm.cityId = '';
        this.cities = [];
      } else {
        this.fetchCitiesDropdown();
      }
    }
  }

  closeDeleteFarmModal() {
    this.showDeleteModal = false;
    this.closeEditFarmModal();
  }

  @Emit('onClose')
  closeEditFarmModal() {}
}
